import { combineReducers, legacy_createStore as createStore } from 'redux';

import modalReducer from './modalStore/reducers';
import { IModalReducer } from './modalStore/types';

export interface IStore {
  modalReducer: IModalReducer;
}

const rootReducer = combineReducers({ modalReducer });
const store = createStore(rootReducer);

export default store;
