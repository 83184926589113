import './style.scss';
import { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

import topLogo from 'assets/icons/emdr_logo_russia.png';
import useDeviceType from 'hooks/useDeviceType';

const Header = () => {
  const location = useLocation();

  const { isLaptop, isDesktop } = useDeviceType();
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const isArticles = location.pathname.includes('articles') ? 'active' : '';

  const hideDropdown = () => setDropdownVisible(false);
  const toogleDropdown = () => setDropdownVisible(!isDropdownVisible);

  return (
    <header>
      <div className='header-content'>
        <img src={topLogo} alt='logo' onClick={() => window.open('https://www.emdr.ru')} />
        {isDesktop || isLaptop ? (
          <nav>
            <NavLink to={'/'} className='navlink'>
              Главная
            </NavLink>
            <NavLink to={'/about'} className='navlink'>
              Обо мне
            </NavLink>
            <NavLink to={'/method'} className='navlink'>
              О методе EMDR
            </NavLink>
            <Link to={'/about#articles'} className={`navlink ${isArticles}`}>
              Мои статьи
            </Link>
            <Link to={'/#education'} className='navlink'>
              Образование
            </Link>
            <Link to={'/#services'} className='navlink'>
              Услуги
            </Link>
            <Link to={'/#reviews'} className='navlink'>
              Отзывы
            </Link>
          </nav>
        ) : (
          <>
            <span
              className={`${isDropdownVisible ? 'icon-cross light' : 'icon-menu'} burger-menu__icon`}
              onClick={toogleDropdown}
            />
            <div className={`header-drop-down ${isDropdownVisible ? 'visible' : 'hide'}`} onClick={hideDropdown}>
              <NavLink to={'/'} className='navlink'>
                Главная
              </NavLink>
              <NavLink to={'/about'} className='navlink'>
                Обо мне
              </NavLink>
              <NavLink to={'/method'} className='navlink'>
                О методе EMDR
              </NavLink>
              <Link to={'/about#articles'} className={`navlink ${isArticles}`}>
                Мои статьи
              </Link>
              <Link to={'/#education'} className='navlink'>
                Образование
              </Link>
              <Link to={'/#services'} className='navlink'>
                Услуги
              </Link>
              <Link to={'/#reviews'} className='navlink'>
                Отзывы
              </Link>
            </div>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
