import './style.scss';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

import { BrowserRouter } from 'react-router-dom';

import AppContent from '../AppContent';

import store from 'store';

const App = () => {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Provider store={store}>
          <AppContent />
        </Provider>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default App;
