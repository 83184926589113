import './style.scss';
import { NavLink } from 'react-router-dom';

import useDeviceType from 'hooks/useDeviceType';
import { cutText } from 'services/cutText';
import { IArticleData } from 'types/IArticle';

export const ArticleItem = (article: IArticleData) => {
  const { isPhone, isDesktop } = useDeviceType();
  const articlesTextLength = isDesktop ? 100 : 60;

  return (
    <NavLink to={`/articles/${article.id}`} className='article' key={article.id}>
      <p className='article__date'>{article.date}</p>
      <div className='article-item'>
        <div className='article-item__logo' style={{ background: `url(${article.image})`, backgroundSize: 'cover' }} />
        <div className='article-item__description'>
          <p className='article-item__title'>{article.title}</p>
          {!isPhone && <p className='article-item__text'>{cutText(article.summary, articlesTextLength)}</p>}
        </div>
      </div>
    </NavLink>
  );
};
