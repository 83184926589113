import './style.scss';
import { AnimatePresence } from 'framer-motion';
import { Suspense } from 'react';
import { useSelector } from 'react-redux';

import { Navigate, useRoutes } from 'react-router-dom';

import Footer from '../Footer';

import Header from '../Header';

import Modal from '../Modal';

import { LazyAboutPage, LazyArticlesPage, LazyMainPage, LazyMethodPage } from 'services/lazyLoaders';

import { selectModalVisible } from 'store/modalStore/selectors';

import { Preloader } from 'ui';

const AppContent = () => {
  const isModalVisible = useSelector(selectModalVisible);

  const routes = useRoutes([
    { path: '/', element: <LazyMainPage /> },
    { path: '/about', element: <LazyAboutPage /> },
    { path: '/method', element: <LazyMethodPage /> },
    {
      path: '/articles',
      children: [
        { path: '', element: <Navigate to={'/articles/0'} /> },
        { path: '/articles/:id', element: <LazyArticlesPage /> },
      ],
    },
    { path: '*', element: <Navigate to={'/'} /> },
  ]);

  return (
    <div className='app-content'>
      <AnimatePresence>{isModalVisible && <Modal />}</AnimatePresence>
      <Header />
      <Suspense fallback={<Preloader />}>
        <AnimatePresence>{routes}</AnimatePresence>
      </Suspense>
      <Footer />
    </div>
  );
};

export default AppContent;
