import { CustomButton } from 'ui';
import './style.scss';

export const SubmitWindow = () => {
  return (
    <div className='submit-window'>
      <h1 className='submit-window__title'>спасибо!</h1>
      <p className='submit-window__text'>
        Я свяжусь с вами <br /> в ближайшее время
      </p>
      <CustomButton text='Вернуться на главную' url='/' />
    </div>
  );
};
