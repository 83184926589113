import { NavLink } from 'react-router-dom';
import './style.scss';

interface ICustomButton {
  text: string;
  url?: string;
  handleFunc?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  icon?: string;
}

export const CustomButton = ({ text, url, handleFunc, icon = 'button-arrow' }: ICustomButton) => {
  return (
    <>
      {url && !handleFunc ? (
        <NavLink to={url} className='custom-button'>
          <p className='custom-button__text'>{text}</p>
          <span className={`icon-arrow ${icon}`} />
        </NavLink>
      ) : (
        <></>
      )}
      {!url && handleFunc ? (
        <button className='custom-button' onClick={handleFunc}>
          <p className='custom-button__text'>{text}</p>
          <span className={`icon-arrow ${icon}`} />
        </button>
      ) : (
        <></>
      )}
    </>
  );
};
