import React from 'react';

import { JsxElement } from 'typescript';

import { IModalAction } from './types';

const initialState = {
  visible: false,
  content: React.createElement('div', null, 'Hello Worild'),
};

const modalReducer = (state = initialState, action: IModalAction<boolean | JsxElement>) => {
  switch (action.type) {
    case 'modal/show':
      return { ...state, visible: action.payload };

    case 'modal/hide':
      return { ...state, visible: action.payload };

    case 'modal/content':
      return { ...state, content: action.payload };

    default:
      return state;
  }
};

export default modalReducer;
