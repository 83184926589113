import './style.scss';
import { motion } from 'framer-motion';
import { MouseEvent } from 'react';
import { useSelector } from 'react-redux';

import useCloseModal from 'hooks/useCloseModal';
import { selectModalContent } from 'store/modalStore/selectors';

const Modal = () => {
  const closeModal = useCloseModal();
  const content = useSelector(selectModalContent);

  const clickOutside = (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    const target = e.target as HTMLElement;
    if (
      target.className === 'modal-window' ||
      target.className === 'closeIcon' ||
      target.className === 'modal__content-wrapper' ||
      target.className.includes('custom-button')
    ) {
      closeModal(e);
    }
  };

  return (
    <motion.div
      className='modal-window'
      onClick={clickOutside}
      transition={{ duration: 0.1 }}
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key='modal-window'
    >
      <div className='modal__content-wrapper'>
        <p className='closeIcon'>+</p>
        <div className='modal__content-container'>{content}</div>
      </div>
    </motion.div>
  );
};

export default Modal;
