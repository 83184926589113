import { useState } from 'react';
import './style.scss';

interface IQuestion {
  id: number;
  title: string;
  text: JSX.Element;
}

export const FAQItem = ({ question }: { question: IQuestion }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`faq-item`}>
      <div className='faq-item-question'>
        <p className='faq-item-question__title'>{question.title}</p>
        <p className={`faq-item-question__plus ${isOpen ? 'open' : 'closed'}`} onClick={() => setIsOpen(!isOpen)}>
          +
        </p>
      </div>
      <div className={`faq-item__answer ${isOpen ? 'visible' : 'hidden'}`}>{question.text}</div>
    </div>
  );
};
