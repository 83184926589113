import { lazy } from 'react';

export const LazyMainPage = lazy(() => {
  return Promise.all([import('../pages/MainPage'), new Promise(resolve => setTimeout(resolve, 500))]).then(
    ([moduleExports]) => moduleExports
  );
});
export const LazyAboutPage = lazy(() => {
  return Promise.all([import('../pages/AboutPage'), new Promise(resolve => setTimeout(resolve, 500))]).then(
    ([moduleExports]) => moduleExports
  );
});
export const LazyMethodPage = lazy(() => {
  return Promise.all([import('../pages/MethodPage'), new Promise(resolve => setTimeout(resolve, 500))]).then(
    ([moduleExports]) => moduleExports
  );
});
export const LazyArticlesPage = lazy(() => {
  return Promise.all([import('../pages/ArticlesPage'), new Promise(resolve => setTimeout(resolve, 500))]).then(
    ([moduleExports]) => moduleExports
  );
});
