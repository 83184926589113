import { useMediaQuery } from 'react-responsive';

const useDeviceType = () => {
  const isPhone = useMediaQuery({ query: '(max-width: 800px)' });
  const isPhonePortrait = useMediaQuery({ maxWidth: 500 });
  const isPhoneLandscape = useMediaQuery({ minWidth: 501, maxWidth: 800 });
  const isPhoneLandscapeNarrow = useMediaQuery({ minWidth: 501, maxWidth: 675 });
  const isTablet = useMediaQuery({ minWidth: 801, maxWidth: 1023 });
  const isLaptop = useMediaQuery({ minWidth: 1024, maxWidth: 1439 });
  const isDesktop = useMediaQuery({ minWidth: 1440 });

  return {
    isPhone: isPhone,
    isPhonePortrait: isPhonePortrait,
    isPhoneLandscape: isPhoneLandscape,
    isPhoneLandscapeNarrow: isPhoneLandscapeNarrow,
    isTablet: isTablet,
    isLaptop: isLaptop,
    isDesktop: isDesktop,
  };
};

export default useDeviceType;
