import './style.scss';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import footerTitle from 'assets/img/footer-title.svg';
import useDeviceType from 'hooks/useDeviceType';

const MobileFooter = () => {
  const [isMenuVisible, setMenuVisible] = useState(false),
    [isDropdownVisible, setDropdownVisible] = useState(false);

  const { isPhone } = useDeviceType();

  const hideDropdown = () => setDropdownVisible(false);
  const toogleDropdown = () => setDropdownVisible(!isDropdownVisible);

  const handleSetMenuVisible = () => {
    if (window.scrollY > 200) {
      setMenuVisible(true);
    } else {
      setMenuVisible(false);
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    isPhone && window.addEventListener('scroll', handleSetMenuVisible);
    return () => {
      window.removeEventListener('scroll', handleSetMenuVisible);
    };
  }, [isPhone]);

  return (
    <>
      <div className={`footer-drop-down ${isDropdownVisible ? 'visible' : 'hide'}`} onClick={hideDropdown}>
        <Link to={'/'} className='navlink'>
          Главная
        </Link>
        <Link to={'/about'} className='navlink'>
          Обо мне
        </Link>
        <Link to={'/method'} className='navlink'>
          О методе EMDR
        </Link>
        <Link to={'/about#articles'} className='navlink'>
          Мои статьи
        </Link>
        <Link to={'/#education'} className='navlink'>
          Образование
        </Link>
        <Link to={'/#services'} className='navlink'>
          Услуги
        </Link>
        <Link to={'/#reviews'} className='navlink'>
          Отзывы
        </Link>
      </div>
      <div className={`mobile-menu ${isMenuVisible ? 'visible' : 'hide'}`}>
        <span className={`${isDropdownVisible ? 'icon-cross' : 'icon-menu'}`} onClick={toogleDropdown} />
        <Link to={'/about'}>
          <img src={footerTitle} alt='' />
        </Link>
        <div className='mobile-menu__social-networks'>
          <span
            className='icon-instagram'
            onClick={() => window.open('https://www.instagram.com/maria.venger.emdr/')}
          />
          <span className='icon-telegram' onClick={() => window.open('https://t.me/vengermaria')} />
          <span className='icon-whatsapp' onClick={() => window.open('https://wa.me/79895206971')} />
        </div>
      </div>
    </>
  );
};

export default MobileFooter;
