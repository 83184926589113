import './style.scss';
import { Link } from 'react-router-dom';

import bottomLogo from 'assets/icons/emdr_logo_europe.png';
import useDeviceType from 'hooks/useDeviceType';

const DesktopFooter = () => {
  const { isDesktop, isLaptop } = useDeviceType();

  return (
    <div className='desktop-footer'>
      <div className='column-one'>
        <img src={bottomLogo} alt='emdr_europe' />
      </div>
      <div className='column-two'>
        <div className='column-content'>
          <p className='column-content__text' onClick={() => window.open('tel:+79895206971')}>
            +7 989 520-69-71
          </p>
          <p className='column-content__text' onClick={() => window.open('mailto:maria.burlachenko@gmail.com')}>
            maria.burlachenko@gmail.com
          </p>
        </div>
      </div>
      {(isDesktop || isLaptop) && (
        <div className='column-three'>
          <div className='column-content'>
            <Link to={'/about'} className='column-content__text'>
              Обо мне
            </Link>
            <Link to={'/about#articles'} className='column-content__text'>
              Мои статьи
            </Link>
            <Link to={'/#services'} className='column-content__text'>
              Услуги
            </Link>
            <Link to={'/method'} className='column-content__text'>
              Описание метода
            </Link>
          </div>
        </div>
      )}
      <div className='column-four'>
        <div className='column-content'>
          <span
            className='icon-instagram footer__icon'
            onClick={() => window.open('https://www.instagram.com/maria.venger.emdr/')}
          >
            <p>@maria.venger.emdr</p>
          </span>
          <span className='icon-telegram footer__icon' onClick={() => window.open('https://t.me/vengermaria')}>
            <p>@vengermaria</p>
          </span>
          <span className='icon-whatsapp footer__icon' onClick={() => window.open('https://wa.me/79895206971')}>
            <p>@vengermaria</p>
          </span>
          <span className='icon-b17 footer__icon' onClick={() => window.open('https://www.b17.ru/mariya_burlachenko/')}>
            <p>@mariya_venger</p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default DesktopFooter;
