import './style.scss';
import DesktopFooter from './components/DesktopFooter';
import MobileFooter from './components/MobileFooter';

import useDeviceType from 'hooks/useDeviceType';

const Footer = () => {
  const { isPhone } = useDeviceType();

  return <footer>{!isPhone ? <DesktopFooter /> : <MobileFooter />}</footer>;
};

export default Footer;
