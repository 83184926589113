import { MouseEvent } from 'react';
import React from 'react';
import { useDispatch } from 'react-redux';

import { addContentModalAction, showModalAction } from '../store/modalStore/actions';

const useCloseModal = () => {
  const dispatch = useDispatch();

  return (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    const target = e.target as HTMLElement;
    target.ownerDocument.body.classList.remove('scroll-hide');
    dispatch(showModalAction(false));
    setTimeout(() => dispatch(addContentModalAction(React.createElement('div'))), 200);
  };
};

export default useCloseModal;
