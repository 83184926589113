import * as React from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import './style.scss';

export const Preloader: React.FunctionComponent = () => {
  return (
    <div className='preloader'>
      <div className='preloader__background' />
      <InfinitySpin width='200' color='#6f688b' />
    </div>
  );
};
